/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { FormPage, formPageGQLString } from 'utils/formBuilder'
import { PhoneInput } from 'vspry-style-components'
import { plutoQuery } from './gqlInterface'

export type Button = {
    title: string
    color?: string
    textColor?: string
    image?: string
    href: string
    index?: number
    counter?: string
    accountType: ('merchant' | 'consumer')[]
    size: 'full' | 'half' | 'regular'
}
export type AppConfig = {
    consumers_enabled: boolean
    consumers_middle_names_collection_enabled: boolean
    consumers_last_name_collection_enabled: boolean
    consumers_second_last_name_collection_enabled: boolean
    consumers_suffix_collection_enabled: boolean
    consumers_dob_collection_enabled: boolean
    consumers_address_collection_enabled: boolean
    secondary_address_enabled: boolean
    consumer_signin_redirect?: string
    merchants_enabled: boolean
    merchant_owner_middle_names_collection_enabled: boolean
    merchant_owner_last_name_collection_enabled: boolean
    merchant_owner_second_last_name_collection_enabled: boolean
    merchant_owner_suffix_collection_enabled: boolean
    merchant_owner_dob_collection_enabled: boolean
    merchant_owner_address_collection_enabled: boolean
    merchant_signin_redirect?: string
    phone_sign_in_enabled: boolean
    help_url?: string
    contact_us_menu_enabled: boolean
    vcode_enabled: boolean
    assets_enabled: string[]
    reimbursement_enabled: boolean
    addProduct_enabled: boolean
    direct_debit_enabled: boolean
    identity_check_enabled: boolean
    loan_payout_enabled: boolean
    device_notifications_enabled: boolean
    document_store_enabled: boolean
    merchant_subscriptions_enabled: boolean
    email_verification_enabled: boolean
    phone_verification_enabled: boolean
    referral_signup_only_enabled: boolean
    review_merchants_enabled: boolean
    health_cover_referral_enabled: boolean
    business_loans_enabled: boolean
    brochure_order_enabled: boolean
    marketing_enabled: boolean
    merchant_help_url?: string
    footer_link_url?: string
    base_country: string
    base_country2: NonNullable<Parameters<typeof PhoneInput>[0]['defaultCountry']>
    base_currency: string
    date_format: 'DDMMYYYY' | 'MMDDYYYY' | 'MMMDDYYYY'
    external_navigations: {
        label: string
        url: string
        iconName: string
        audience: string
    }[]
    external_register?: string
    default_offers_image?: string
    no_product_home_buttons: Button[]
    financial_statement_year_range: number
    bank_statement_day_coverage: number
    financial_statements_business_loan_requirement: number
    bank_statement_month_coverage: number
    minimum_trading_history_months_business_loan_requirement: number
    minimum_revenue_business_loan_requirement: number
    minimum_age_business_loan_requirement: number
    brand_name: string
    how_to_videos: { title: string; description?: string; link: string; audience: 'All' | 'Consumer' | 'Merchant' }[]
}
export const getRemoteConfig = async () => plutoQuery<AppConfig>`
    remoteConfig {
        consumers_enabled
        consumers_middle_names_collection_enabled
        consumers_last_name_collection_enabled
        consumers_second_last_name_collection_enabled
        consumers_suffix_collection_enabled
        consumers_dob_collection_enabled
        consumers_address_collection_enabled
        secondary_address_enabled
        consumer_signin_redirect
        merchants_enabled
        merchant_owner_middle_names_collection_enabled
        merchant_owner_last_name_collection_enabled
        merchant_owner_second_last_name_collection_enabled
        merchant_owner_suffix_collection_enabled
        merchant_owner_dob_collection_enabled
        merchant_owner_address_collection_enabled
        merchant_signin_redirect
        phone_sign_in_enabled
        help_url
        contact_us_menu_enabled
        vcode_enabled
        reimbursement_enabled
        assets_enabled
        addProduct_enabled
        direct_debit_enabled
        identity_check_enabled
        loan_payout_enabled
        device_notifications_enabled
        document_store_enabled
        merchant_subscriptions_enabled
        email_verification_enabled
        phone_verification_enabled
        referral_signup_only_enabled
        review_merchants_enabled
        health_cover_referral_enabled
        business_loans_enabled
        brochure_order_enabled
        marketing_enabled
        merchant_help_url
        footer_link_url
        base_country
        base_country2
        base_currency
        date_format
        external_navigations {
            label
            url
            iconName
            audience
        }
        external_register
        default_offers_image
        no_product_home_buttons {
            title
            color
            textColor
            image
            href
            index
            counter
            accountType
            size
        }
        financial_statement_year_range
        bank_statement_day_coverage
        financial_statements_business_loan_requirement
        bank_statement_month_coverage
        minimum_trading_history_months_business_loan_requirement
        minimum_revenue_business_loan_requirement
        minimum_age_business_loan_requirement
        brand_name
        how_to_videos {
            title
            description
            link
            audience
        }
    }
`

export const getConsumerSignupForm = async () => {
    const res = await plutoQuery<FormPage[]>`consumerSignupForm ${formPageGQLString}`
    return res?.sort((a, b) => a.index - b.index) ?? []
}

export const getMerchantSignupForm = async () => {
    const res = await plutoQuery<FormPage[]>`merchantSignupForm ${formPageGQLString}`
    return res?.sort((a, b) => a.index - b.index) ?? []
}

export const getAssetAddForm = async (assetID: string) => {
    const res = await plutoQuery<FormPage[]>`assetAddForm(assetID: "${assetID}") ${formPageGQLString}`
    return res?.sort((a, b) => a.index - b.index) ?? []
}
