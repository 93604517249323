/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { UiNode, UiNodeInputAttributes } from '@ory/client'

import Button, { isButtonNode } from './Button'
import Password, { isPasswordNode } from './Password'
import Text, { isTextNode } from './Text'

type InputNode = Omit<UiNode, 'attributes'> & { attributes: { node_type: 'input' } & UiNodeInputAttributes }
export const isInputNode = (a: UiNode): a is InputNode => {
    if (a.attributes.node_type === 'input') return true
    return false
}
export function Input(node: InputNode) {
    if (isButtonNode(node)) return <Button {...node} />
    if (isPasswordNode(node)) return <Password {...node} />
    if (isTextNode(node)) return <Text {...node} />
    return null
}
