/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useState } from 'react'
import { UiNode, UiNodeInputAttributes, UiNodeInputAttributesTypeEnum } from '@ory/client'
import validator from 'validator'
import { TextInput } from 'vspry-style-components'
import { useAuth } from 'context/authContext'
import { useLocale } from 'context/localeContext'
import { translateAuthCode } from './util'

type TextUiNodeInputAttributesTypeEnumMap = Omit<typeof UiNodeInputAttributesTypeEnum, 'Button' | 'Checkbox' | 'Submit'>
type TextUiNodeInputAttributesTypeEnum = TextUiNodeInputAttributesTypeEnumMap[keyof TextUiNodeInputAttributesTypeEnumMap]
type TextNode = Omit<UiNode, 'attributes'> & { attributes: { node_type: 'input'; type: TextUiNodeInputAttributesTypeEnum } & UiNodeInputAttributes }
export const isTextNode = (a: UiNode): a is TextNode => {
    if (a.attributes.node_type === 'input' && a.attributes.type !== 'button' && a.attributes.type !== 'checkbox' && a.attributes.type !== 'submit')
        return true
    return false
}
export default function Text({ attributes, meta, messages }: TextNode) {
    const { translateMultivalue } = useLocale()
    const { identity } = useAuth()
    const [value, setValue] = useState(attributes.value ?? identity?.[attributes.name as keyof typeof identity] ?? '')

    return (
        <TextInput
            inputProps={{ ...attributes }}
            label={translateAuthCode(translateMultivalue, meta.label)}
            value={value}
            handleChange={(v) => setValue(v)}
            id={`auth-input-${attributes.name}`}
            validator={attributes.type === 'email' ? (v) => validator.isEmail(v) : undefined}
            guide={messages[0]?.text}
        />
    )
}
