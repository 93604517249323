/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { createContext, ReactNode, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useBrowserStorage, useFunctionState } from 'vspry-hooks'

import { Account, Contact, getUserContact, Holding, recordUserLogin } from 'api/common'
import { Case, getPatient, Patient } from 'api/marketplace'
import auth from 'services/auth'
import { AuthIdentity } from 'services/auth/interface'

type AuthProviderState = {
    identity: AuthIdentity | null
    user: Contact | null
    account: Account | null
    patient: Patient | null
    products: (Holding | Case)[]
    currentProduct: Holding | Case | null
    tsAndCs: boolean
    fetching: boolean
    error: string | null
}

const init: AuthProviderState = {
    identity: null,
    user: null,
    account: null,
    patient: null,
    products: [],
    currentProduct: null,
    tsAndCs: false,
    fetching: true,
    error: null,
}

const useAuthProvider = () => {
    const [state, setState] = useFunctionState(init)
    const { identity, user, account, products, currentProduct, patient, tsAndCs, fetching, error } = state
    const [accID, setAccID] = useBrowserStorage('accountID', '')

    const setCurrentProduct = (p: Holding | Case | null) => {
        setState({ currentProduct: p })
    }

    const setCurrentAccount = (a: Account | null) => {
        setState({ account: a, currentProduct: a?.holdings[0] ?? null, products: a?.holdings ?? [] })
        if (a) setAccID(a.id)
    }

    const findAccount = (accounts: Account[]) => {
        if (account && accounts.find((a) => a.id === account.id)) return accounts.find((a) => a.id === account.id)
        if (accID && accounts.find((a) => a.id === accID)) return accounts.find((a) => a.id === accID)
        return accounts[0]
    }

    // retrieves a user
    const getUser = async () => {
        console.info(`Fetching user contact ...`)
        setState({ fetching: true, error: null })
        const [u, p] = await Promise.all([getUserContact(), getPatient()])
        if (!u) return setState({ fetching: false, error: 'Error retrieving user' })
        const ps = [...(findAccount(u.accounts)?.holdings ?? []), ...(p?.cases.map((c) => ({ ...c, asset: 'Case' })) || [])]
        return setState({
            user: u,
            account: findAccount(u.accounts),
            tsAndCs: u.tsAndCs,
            patient: p,
            currentProduct: currentProduct ? ps.find((e) => e.id === currentProduct.id) ?? ps[0] : ps[0],
            products: ps,
            fetching: false,
        })
    }

    // logout a user
    const logout = async () => auth.logout()

    // auth state handler function
    const handleAuthStateUpdate = (u: AuthIdentity | null) => {
        setState({ identity: u })
        if (u) {
            recordUserLogin()
            getUser()
        } else {
            setState({ ...init, fetching: false })
        }
    }
    useEffect(() => {
        auth.setOnChange((u) => handleAuthStateUpdate(u))
        auth.getUser()
    }, [])

    return {
        identity,
        user,
        account,
        setCurrentAccount,
        patient,
        products,
        currentProduct,
        setCurrentProduct,
        tsAndCs,
        getUser,
        fetching,
        logout,
        error,
    }
}

type AuthContext = ReturnType<typeof useAuthProvider>
const context = createContext<AuthContext>(init as AuthContext)
const { Provider } = context

export function AuthProvider({ children }: { children: ReactNode }) {
    return <Provider value={useAuthProvider()}>{children}</Provider>
}

export const useAuth = () => useContext(context)

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
