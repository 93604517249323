/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect } from 'react'
import { UiNode, UiNodeGroupEnum } from '@ory/client'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useFunctionState } from 'vspry-hooks'
import { Button, FlexBox, Text } from 'vspry-style-components'

import { useAuth } from 'context/authContext'
import { translateContextless, useLocale } from 'context/localeContext'

import { isTenant, Type } from 'services/auth/interface'
import KittedTile from 'components/KittedTile'
import { Popup } from 'semantic-ui-react'
import Node from './Node'
import KratosAuthProvider, { KratosFlow } from '../provider'
import { translateAuthCode } from './util'

const methods = {
    password: translateContextless('inputs.password'),
    webauthn: translateContextless('inputs.webauthn'),
}

export default function Flow({ type, auth }: { type: Type; auth: KratosAuthProvider }) {
    const [searchParams] = useSearchParams()
    const location = useLocation()
    const navigate = useNavigate()
    const { translate, translateMultivalue } = useLocale()
    const { logout, identity } = useAuth()
    const [{ flow, method }, setState] = useFunctionState<{ flow?: KratosFlow; method?: keyof typeof methods }>({})

    useEffect(() => {
        const tenant = searchParams.get('tenant')
        if (tenant && isTenant(tenant)) auth.setTenant(tenant)

        const redirect = searchParams.get('redirect') ?? undefined
        const flowID = searchParams.get('flow') ?? undefined
        auth.getFlow(type, flowID, redirect ? decodeURIComponent(redirect) : location.pathname).then((d) => setState({ flow: d }))
    }, [location])

    const groups = flow?.ui.nodes.reduce((t, c) => ({ ...t, [c.group]: [...(t[c.group] ?? []), c] }), {} as Record<UiNodeGroupEnum, UiNode[]>)

    useEffect(() => {
        const e = flow?.ui.nodes.find((n) => n.messages.length > 0)
        if (groups && e && !method) setState({ method: Object.keys(groups).find((k) => e.group === k) as keyof typeof methods })
    }, [groups])

    const renderGroup = (group: UiNode[]) => (
        <FlexBox $column $fitted gap='small'>
            {group.map((n) => (
                <Node node={n} signin={type === 'login'} />
            ))}
        </FlexBox>
    )

    if (!flow || !groups) return <p>{translate('common.fetching')}</p>

    if (type === 'settings')
        return (
            <form action={flow.ui.action} method={flow.ui.method} style={{ width: '100%' }}>
                <FlexBox $column gap='medium'>
                    {'password' in groups && (
                        <KittedTile
                            title={translate('pages.profile.loginMethods.password.title')}
                            description={translate('pages.profile.loginMethods.password.description')}
                        >
                            {renderGroup(groups['password'])}
                        </KittedTile>
                    )}
                    {'webauthn' in groups && (
                        <KittedTile
                            title={translate('pages.profile.loginMethods.passkey.title')}
                            description={translate('pages.profile.loginMethods.passkey.description')}
                        >
                            {renderGroup(groups['webauthn'])}
                        </KittedTile>
                    )}
                    <Text
                        size='small'
                        $bold
                        onClick={() => {
                            navigate('/profile')
                        }}
                        margin='no'
                    >
                        {translate('buttons.back')}
                    </Text>
                </FlexBox>
                {renderGroup(groups['default'])}
            </form>
        )

    return (
        <FlexBox $column $align gap='medium' width='75%'>
            {flow.ui.messages &&
                flow.ui.messages &&
                flow.ui.messages.map((m) => (
                    <Text size='xSmall' margin='no'>
                        {translateAuthCode(translateMultivalue, m)}
                    </Text>
                ))}
            {(type === 'recovery' || Object.keys(groups).length <= 2 || method) && (
                <form action={flow.ui.action} method={flow.ui.method} style={{ width: '100%' }}>
                    <FlexBox $column $fitted gap='small'>
                        {Object.keys(groups)
                            .filter((k) => (type !== 'recovery' && Object.keys(groups).length > 2 ? k === 'default' || k === method : true))
                            .map((k) => renderGroup(groups[k as keyof typeof groups]))}
                    </FlexBox>
                </form>
            )}
            {flow?.state === 'choose_method' && !method && Object.keys(groups).length > 2 && (
                <FlexBox $column $fitted gap='small'>
                    {Object.keys(groups)
                        .filter((k) => k !== 'default')
                        .map((k) => (
                            <Popup
                                trigger={
                                    <Button id={`auth-flow-method-${k}`} onClick={() => setState({ method: k as keyof typeof methods })}>
                                        {translateMultivalue('authFlows', type)} {translate('common.with')} {methods[k as keyof typeof methods]}
                                    </Button>
                                }
                                on={['hover']}
                                position='top center'
                            >
                                {translateMultivalue('authMethods', k as any)}
                            </Popup>
                        ))}
                </FlexBox>
            )}
            <Text
                size='small'
                $bold
                onClick={() => {
                    if (method) {
                        setState({ method: undefined })
                        return
                    }
                    navigate('/')
                    if (identity) logout()
                }}
                margin='no'
            >
                {translate('buttons.back')}
            </Text>
        </FlexBox>
    )
}
